import { Form as FormikForm, Formik, FormikProps } from "formik";
import React, { useCallback, useMemo, useEffect, useState } from "react";
import * as Yup from "yup";

import CloseIcon from "../../images/Close-2.svg";
import { cn } from "../../utils";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import Heading from "../Typography/Heading/Heading";
import * as styles from "./Form.module.scss";
import IForm, { TErrorKey, TTouchedKey } from "./Form.type";
import FormInput from "./FormInput/FormInput";
import { IFormField } from "./FormInput/FormInput.type";
import { navigate } from "gatsby";

const Form = ({
    fields,
    className,
    submitButtonText,
    formTitle,
    caption,
    closePopupFn,
    defaultSubmitAction,
    defaultHubspotListId,
    defaultTargetEmail,
    emailStructure,
}: IForm) => {
    const [investWithUsChoosed, setInvestWithUsChoosed] = useState(false);

    const contactTypeField = useMemo(
        () => fields.find((field) => field.name === "contact_type"),
        []
    );

    const onSubmit = useCallback((values: { [index: string]: string }) => {
        const errors = {
            hubspot: false,
            email: false,
        };

        const { actions, targetEmail } = getSubmitData(
            values,
            defaultSubmitAction,
            defaultHubspotListId,
            defaultTargetEmail,
            contactTypeField
        );

        sendEmail(
            values,
            targetEmail,
            fields,
            emailStructure,
            (actions as "email" | "hubspot_and_email") || "email"
        );

        if (!errors.hubspot && !errors.email) {
            if (actions === "hubspot_and_email") {
                navigate("/thank-you/", {
                    state: {
                        redirected: true,
                    },
                });
            } else {
                navigate("/thanks/", {
                    state: {
                        redirected: true,
                    },
                });
            }
        }
    }, []);

    return (
        <div
            className={cn(
                styles.element,
                className,
                contactTypeField && styles.hasContactType
            )}
            onClick={(e) => e.stopPropagation()}
        >
            {closePopupFn && (
                <button className={styles.close} onClick={closePopupFn}>
                    <Icon
                        altText="Close popup"
                        localFile={{ publicURL: CloseIcon }}
                    />
                </button>
            )}

            <Formik
                initialValues={getInitialValues(fields)}
                validationSchema={getValidationSchema(
                    fields,
                    investWithUsChoosed
                )}
                onSubmit={onSubmit}
            >
                {({ errors, touched, values }: FormikProps<{}>) => (
                    <FormikForm>
                        {formTitle && (
                            <Heading level="h3" className={styles.formHeading}>
                                {`<span>${formTitle}</span>`}
                            </Heading>
                        )}
                        {setInvestWithUsChoosed(
                            values.contact_type === "Invest With Us"
                                ? true
                                : false
                        )}
                        {fields.map((field) => (
                            <FormInput
                                key={field.name}
                                field={field}
                                touched={touched[field.name as TTouchedKey]}
                                error={errors[field.name as TErrorKey]}
                                className={
                                    field.name === "contact_type" &&
                                    styles.contactTypeField
                                }
                            />
                        ))}
                        {caption && (
                            <small
                                className={styles.caption}
                                dangerouslySetInnerHTML={{ __html: caption }}
                            />
                        )}
                        <Button className={styles.submit} type="small" submit>
                            {submitButtonText}
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default Form;

const sendEmail = (
    values: { [index: string]: string },
    targetEmail: string,
    fields: IFormField[],
    emailStructre: string,
    action: "email" | "hubspot_and_email"
) => {
    let emailHTML = emailStructre;
    const { contact_type, ...body } = values;

    Object.entries(body).forEach(([key, value]) => {
        const field = fields.find((field) => field.name === key);
        emailHTML = emailHTML
            .replace(`{${key}}`, field!.label)
            .replace(`{${key}_value}`, value);
    });

    const requestBody = {
        ...body,
        email_html: emailHTML,
        target_email: targetEmail,
        email: values.email,
        name: values.name,
        subject: contact_type ? `Contact - ${contact_type}` : "Get Started",
        action: action,
    };

    fetch(`${process.env.GATSBY_WP_URL}/wp-json/christina/sendemail`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
            "Content-Type": "application/json",
        },
    }).then((resp) => resp.text());
    // .then((json) => console.log(json))
    // .catch((err) => console.log(err));
};

const getInitialValues = (fields: IFormField[]) =>
    fields.reduce(
        (initVal, field) => ({
            ...initVal,
            [field.name]: "",
        }),
        {}
    );

const getValidationSchema = (
    fields: IFormField[],
    investWithUsChoosed: Boolean
) =>
    Yup.object().shape(
        fields.reduce((yupObject, field) => {
            const inputType = field.fieldGroupName
                .split("_")
                .pop()
                ?.toLowerCase();

            let yupField = Yup.string();

            switch (inputType) {
                case "input":
                    switch (field.type) {
                        case "text":
                            yupField = yupField
                                .min(2, "Too short")
                                .max(70, "Too long");
                            break;
                        case "email":
                            yupField = yupField.email("Invalid email format");
                            break;
                        case "tel":
                            const phoneRegExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
                            yupField = yupField.matches(
                                phoneRegExp,
                                "Phone number format is not valid"
                            );
                            break;
                    }
                    break;
                case "select":
                    yupField = yupField
                        .min(5, "Use at least 5 characters")
                        .max(70, "Option is too long");
                    break;
            }

            if (
                (field.required && !field.investWithUsSelected) ||
                (investWithUsChoosed && field.investWithUsSelected)
            )
                yupField = yupField.required(
                    field.label.length < 20
                        ? `${field.label} field is required`
                        : `This field is required`
                );

            return {
                ...yupObject,
                [field.name]: yupField,
            };
        }, {})
    );

const getSubmitData = (
    values: { [index: string]: string },
    defaultSubmitAction: string,
    defaultHubspotListId: string,
    defaultTargetEmail: string,
    contactTypeField?: IFormField
) => {
    let actions, hubspotListId, targetEmail;

    if (defaultSubmitAction !== "by_contact_type") {
        actions = defaultSubmitAction;
        hubspotListId = defaultHubspotListId;
        targetEmail = defaultTargetEmail;
    } else {
        const chosenContactType = contactTypeField!.options?.find(
            (option) => option.value === values["contact_type"]
        );

        actions = chosenContactType!.submitAction;
        hubspotListId = chosenContactType!.hubspotListId;
        targetEmail = chosenContactType!.targetEmail;
    }

    return { actions, hubspotListId, targetEmail };
};
